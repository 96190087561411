import { Button } from '@mui/material';
import { ReactNode } from 'react';

export const StageButton: React.FC<{
  amount: number;
  amountIcon?: ReactNode;
  active: boolean;
  onClick: () => void;
  disabled: boolean;
}> = ({ amount, amountIcon, active, onClick, disabled }) => (
  <Button
    variant="text"
    disabled={disabled}
    sx={(theme) => ({
      borderRadius: 2,
      height: '45px',
      width: '100%',
      color: active ? theme.palette.text.brand : 'rgba(0, 0, 0, 0.87)',
      background: active
        ? theme.palette.background.brandLight
        : disabled
        ? 'transparent'
        : theme.palette.highlight.actionable,
      fontWeight: 500,
      textAlign: 'left',
      fontSize: '30px',
      display: 'flex',
      alignItems: 'center',
      gap: 1,
      justifyContent: 'flex-start',
      marginTop: 0.5,
      border: '2px solid white',
    })}
    onClick={onClick}
  >
    {amount}
    {amountIcon}
  </Button>
);
