import { useCallback, useMemo, useState } from 'react';
import { orderBy as orderByFunc } from 'lodash';

import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material';
import { CandidateScore, CountryFlag, Flex } from '@components';

import {
  AppRoutes,
  CandidateDetailsByStageSortingOptions,
  OrderDirection,
} from '@constants';

import { ICandidateByStage } from '@types';
import {
  formatDate,
  getCandidateName,
  isCandidateDisqualified,
  isScoreIncomplete,
  transormCandidateStatus,
} from '@utils';

export const CandidatesDetailsByStage: React.FC<{
  title: string;
  dateColumnTitle: string;
  data: ICandidateByStage[] | null;
}> = ({ title, dateColumnTitle, data }) => {
  const [order, setOrder] = useState<OrderDirection>(OrderDirection.ASC);
  const [sortBy, setSortBy] = useState<CandidateDetailsByStageSortingOptions>();

  const openCandidateDetailsInNewTab = (candidateId: string) => {
    window.open(
      `/${AppRoutes.CANDIDATES}/${candidateId}`,
      '_blank',
      'noreferrer',
    );
  };

  const sortedCandidates = useMemo(() => {
    if (data && order && sortBy) {
      return [
        ...orderByFunc(
          data.filter(
            (a) =>
              !isCandidateDisqualified(a.score) && !isScoreIncomplete(a.score),
          ),
          sortBy,
          order,
        ),
        ...orderByFunc(
          data.filter(
            (a) =>
              isCandidateDisqualified(a.score) || isScoreIncomplete(a.score),
          ),
          sortBy,
          order,
        ),
      ];
    }

    return data;
  }, [order, sortBy, data]);

  const handleClick = useCallback(
    (value: CandidateDetailsByStageSortingOptions) => {
      if (value === sortBy) {
        const toggleOrder =
          order === OrderDirection.ASC
            ? OrderDirection.DESC
            : OrderDirection.ASC;
        setOrder(toggleOrder);
      } else {
        setSortBy(value);
        setOrder(OrderDirection.DESC);
      }
    },
    [sortBy, order],
  );

  if (!data?.length) return null;

  return (
    <Box width="100%">
      <Typography color="text.primary" mt={'1.5rem'} variant="h4" mb={1} ml={1}>
        {title}
      </Typography>
      <TableContainer sx={{ width: '100%' }}>
        <Table aria-label="Potential candidates" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell
                sx={(theme) => ({
                  background: theme.palette.background.backgroundPrimary,
                  width: '40%',
                  borderRadius: '8px 0 0 8px',
                  borderBottom: 'none',
                  padding: '8px',
                })}
              >
                Full name
              </TableCell>
              <TableCell
                align="center"
                sx={(theme) => ({
                  background: theme.palette.background.backgroundPrimary,
                  borderBottom: 'none',
                  padding: '8px',
                  width: '20%',
                })}
              >
                {dateColumnTitle}
              </TableCell>
              <TableCell
                align="center"
                sx={(theme) => ({
                  background: theme.palette.background.backgroundPrimary,
                  borderBottom: 'none',
                  padding: '8px',
                  width: '30%',
                })}
              >
                Current status
              </TableCell>
              <TableCell
                sx={(theme) => ({
                  background: theme.palette.background.backgroundPrimary,
                  textAlign: 'left',
                  borderRadius: '0 8px 8px 0',
                  borderBottom: 'none',
                  padding: '8px',
                  width: '10%',
                })}
              >
                <TableSortLabel
                  direction={order}
                  active={sortBy == CandidateDetailsByStageSortingOptions.Score}
                  onClick={() =>
                    handleClick(CandidateDetailsByStageSortingOptions.Score)
                  }
                >
                  Score
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedCandidates?.map((candidate) => {
              return (
                <TableRow
                  key={candidate.id}
                  sx={(theme) => ({
                    cursor: 'pointer',
                    '&:last-child td, &:last-child th': {
                      border: 0,
                    },
                    '&:hover': {
                      background: theme.palette.highlight.actionable,
                    },
                  })}
                  onClick={() => openCandidateDetailsInNewTab(candidate.id)}
                >
                  <TableCell
                    sx={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      padding: '5px',
                    }}
                  >
                    <Flex gap={1} alignItems="start">
                      <CountryFlag country={candidate.country} />
                      <Flex flexDirection="column" alignItems="start">
                        <Typography variant="body1" color="text.primary">
                          {getCandidateName({
                            fullName: candidate.fullName,
                            firstName: candidate.firstName,
                            lastName: candidate.lastName,
                            englishFirstName: candidate.englishFirstName,
                            englishLastName: candidate.englishLastName,
                          })}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {candidate.seniority} {candidate.technicalFlow}
                        </Typography>
                      </Flex>
                    </Flex>
                  </TableCell>
                  <TableCell
                    sx={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      padding: '5px',
                    }}
                    align="center"
                  >
                    {candidate?.date ? formatDate(candidate?.date) : '-'}
                  </TableCell>
                  <TableCell
                    sx={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      padding: '5px',
                    }}
                    align="center"
                  >
                    {transormCandidateStatus(candidate.status) || '-'}
                  </TableCell>

                  <TableCell align="center" sx={{ padding: '5px' }}>
                    <CandidateScore score={candidate.score} />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
